@use "@carbon/react";
@use "@carbon/styles/scss/type";
@use "@carbon/react/scss/colors";

.cds--header__name {
  padding: 0 20px !important;
}

.cds--header__submenu {
  list-style-type: none;
}

.cds--header__menu-bar {
  gap: 2px;
}

.cds--header__menu-item {
  cursor: pointer;
}

.cds--header__menu-title[aria-expanded="true"] + .cds--header__menu {
  inline-size: 100% !important;
}

a.cds--header__menu-item[aria-current="page"]:focus,
a.cds--header__menu-item.cds--header__menu-item--current:focus {
  border: 2px solid transparent !important;
}

a.cds--header__menu-item[aria-current="page"]::after,
.cds--header__menu-item--current::after {
  background-image: linear-gradient(100deg, #5fe9d0 1.95%, #36bffa 100%);
}

.navLink {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  block-size: 100%;
  color: #525252;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.125rem;
  user-select: none;
  padding: 0px 1rem;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-image: initial;
  text-decoration: none;
  transition: background-color 110ms, border-color 110ms, color 110ms;
}

.header__menu-item-active::after{
  position: absolute;
  background-color: var(--cds-border-interactive, #0f62fe);
  block-size: 3px;
  content: "";
  inline-size: calc(100% + 4px);
  inset-block-end: -2px;
  inset-inline-start: -2px;
}

@media (min-width: 20rem) {
  .cds--header__nav {
    display: block !important;
  }
}
