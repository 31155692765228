@use "@carbon/react";
@use "@carbon/styles/scss/type";
@use "@carbon/react/scss/colors";
@use "@carbon/layout" as *;
@use "@carbon/type" as *;

.limits {
  margin-left: 256px;
  height: calc(100vh - 48px);
  width: 382px;
  border-right: 1px solid rgba(198, 198, 198, 1);
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .limits_title {
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px 12px;
    border-bottom: 1px solid rgba(198, 198, 198, 1);
    h3 {
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
    }
    .limits_title_buttons {
      display: flex;
      gap: 8px;
      .cds--btn{
        padding-inline: 1rem;
      }
    }
  }

  .limits_limit {
    width: 100%;
    border-bottom: 1px solid rgba(198, 198, 198, 1);
    padding: 16px;
    .limits_limit_select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .limits_limit_select_text {
        display: flex;
        align-items: center;
        gap: 10px;
        h4 {
          font-size: 16px;
        }
        .limits_limit_select_text_input {
          width: 100px;
          .cds--text-input {
            background-color: #fff !important;
          }
        }
      }
      .limits_limit_select_buttons {
        display: flex;
        align-items: center;
        gap: 16px;
        .cds--popover-container
          .cds--tooltip-trigger__wrapper
          .cds--btn--icon-only {
          padding-top: 4px !important;
        }
      }
    }
    .limits_limit_toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      &:empty {
        display: none;
      }
    }
    .limits_limit_edit {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-top: 20px;
      gap: 8px;
    }
  }
  .new-limit {
    background-color: rgba(141, 141, 141, 0.2);
  }
  .edited-limit {
    background-color: rgba(237, 245, 255, 1);
  }
  .limits_add {
    margin: 20px 0;
  }
}

.cds--number--sm .cds--number__controls {
  display: none;
}

.cds--number--sm.cds--number input[type="number"] {
  padding-inline-end: 1rem;
  width: 100px;
  min-inline-size: 0;
}

.cds--slider-container {
  width: 100% !important;
}

.cds--slider__thumb {
  background-color: #fff;
  border: 2px solid #161616;
}

.cds--slider__thumb-wrapper {
  position: absolute;
  z-index: 3;
  block-size: 1.25rem;
  inline-size: 1.25rem;
  transform: translate(-50%, -50%);
  --cds-tooltip-padding-inline: 0.5rem;
  --cds-popover-offset: 0.5rem;
}

.cds--slider__track {
  background: linear-gradient(
    to left,
    rgba(250, 77, 86, 1),
    rgba(255, 131, 43, 1),
    rgba(36, 161, 72, 1)
  );
  border-radius: 10px;
  height: 4px;
}

.cds--slider__filled-track {
  display: none;
}

.cds--toggle__text {
  font-size: 12px;
}

.limits_limit_select_buttons_delete {
  fill: rgba(218, 30, 40, 1) !important;
}

.skeleton_placeholder {
  width: 100%;
}
