@use "@carbon/react";
@use "@carbon/styles/scss/type";
@use '@carbon/react/scss/colors';

.cds--side-nav{
    border-right: 1px solid rgba(198, 198, 198, 1);
}

.cds--side-nav__item{
    overflow: hidden;
    block-size: auto;
    inline-size: auto;
}

.cds--side-nav__link{
    outline: 2px solid rgba(0, 0, 0, 0);
    outline-offset: -2px;
    font-size: var(--cds-heading-compact-01-font-size, 0.875rem);
    font-weight: var(--cds-heading-compact-01-font-weight, 600);
    line-height: var(--cds-heading-compact-01-line-height, 1.28572);
    letter-spacing: var(--cds-heading-compact-01-letter-spacing, 0.16px);
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    min-block-size: 2rem;
    text-decoration: none;
    transition: color 110ms, background-color 110ms, outline 110ms;
    cursor: pointer;
}

.sideNav-active::before {
    position: absolute;
    background-color: var(--cds-border-interactive, #0f62fe);
    content: "";
    inline-size: 3px;
    inset-block: 0;
    inset-inline-start: 0;
}